<template>
  <ConditionFilter :filterConfig="filterConfig" @doQuery="search" :show-date-ranger="true" :do-query-when-start="true"
                   :close-after-query="true" ref="conditionFilter"></ConditionFilter>
  <div v-loading="loading">
    <ListHeaderTabs :default-tab-id="1" :configs="tabsConfig" @tab-change="search" ref="tabFilter"></ListHeaderTabs>
    <div class="table">
      <el-table
          :data="listData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="left" header-align="left" width="200">
          <template #default="scope">
            {{ scope.row.orderNo }}
            <span v-if="currentTab===''" >
              <br />
              <span v-if="scope.row.assignStatus==='UN_ASSIGNED'" class="akz-tag info">未报送</span>
              <span v-if="scope.row.assignStatus==='ASSIGNED'" class="akz-tag primary">已报送</span>
              <span v-if="scope.row.assignStatus==='RETURNING'" class="akz-tag warning">退回中</span>
              <span v-if="scope.row.assignStatus==='CONFIRMED'" class="akz-tag success">已确认</span>
              <span v-if="scope.row.assignStatus==='FREEZE'" class="akz-tag deep">冻结</span>
            </span>
            <span v-if="currentTab!==''" >
              <br />
            </span>
            <span v-if="scope.row.orderStatus==='PART_REFUND'" class="akz-tag warning">部分退款</span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectType" label="报名科目" align="left" header-align="left" width="200px">
          <template #default="scope">
            {{ scope.row.productName }}
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="姓名" align="center" width="60">
        </el-table-column>
        <el-table-column label="资料完善" align="center" width="80">
          <template #default="scope">
            <span v-if="scope.row.personInfoCheck===true" class="akz-tag success">个人</span>
            <span v-else class="akz-tag info">个人</span>
            <span v-if="scope.row.companyInfoCheck===true" class="akz-tag success">公司</span>
            <span v-else class="akz-tag info">公司</span>
            <span v-if="scope.row.postInfoCheck===true" class="akz-tag success">快递</span>
            <span v-else class="akz-tag info">快递</span>
          </template>
        </el-table-column>
        <el-table-column prop="__description" label="备注" align="left" header-align="left" >
        </el-table-column>
        <el-table-column prop="__dealTime" label="报名时间" align="center" width="160">
        </el-table-column>
        <el-table-column label="操作" width="200" align="left">
          <template #default="scope">
            <el-button link class="akz-link" @click="edit(scope.row.orderNo)">详情</el-button>
            <el-button link class="akz-link" @click="doDownload(scope.row.orderNo)">材料下载</el-button>
            <el-popconfirm v-if="scope.row.orderStatus==='ENABLE' && scope.row.signStatus==='UN_SIGN'"  confirm-button-text="确定"
                           cancel-button-text="取消"
                           title="是否确认?"
                           @confirm="updateStatus(scope.row.id,'ENABLE')">
              <template #reference>
                <el-button link class="akz-link">确认</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-multi-btns">
      <span>批量操作：</span>
      <el-button link class="akz-link" @click="doDownloadBatch">下载</el-button>
    </div>
    <div class="pagination-item">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="record.currentIndex"
          :page-sizes="[20, 50, 100]"
          :page-size="record.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="record.total"></el-pagination>
    </div>
  </div>
  <SignMngDetail ref="signMngDetailRef" @refresh-list="search" />
</template>

<script>
import ListHeaderTabs from "@/components/frame/tabs/ListHeaderTabs";
import {ref} from "vue";
import dateFormat from "@/components/util/DateFormat";
import OssBaseOrderApi from "@/api/OssBaseOrderApi";
import SignMngDetail from "@/components/SignMng/SignMngDetail";
import {ElMessage as Message} from 'element-plus'
import OssOrderTrainingApi from "@/api/OssOrderTrainingApi";

export default {
  name: "SignMng",
  components: {ListHeaderTabs,SignMngDetail },
  emits: ["goto-module"],
  mixins:[dateFormat,OssBaseOrderApi,OssOrderTrainingApi],
  setup() {
    const conditionFilter = ref(null)
    const tabFilter = ref(null)
    const signMngDetailRef = ref(null)
    return {
      conditionFilter,tabFilter,signMngDetailRef
    }
  },
  data() {
    return {
      loading:false,
      currentTab:"UN_ASSIGNED",
      record: {
        list: [],
        currentIndex: 1,
        total: 100,
        pageSize: 20,
        selected: [],
        statistic:{
          startTime:'2000-01-01 00:00:00',
          endTime:'2000-01-01 00:00:00',
          orderCount: 0,
          preAmount: 0,
          amount: 0
        }
      },
      filterConfig: [
        {
          type: 'input',
          title: '订单号',
          placeHolder: '请输入...',
          bindField: 'orderNo',
          showInMain: true
        },{
          type: 'input',
          title: '产品编号',
          placeHolder: '请输入...',
          bindField: 'productNo'
        },{
          type: 'input',
          title: '产品名称',
          placeHolder: '请输入...',
          bindField: 'productName',
          showInMain: true
        },{
          type: 'input',
          title: '备注',
          placeHolder: '请输入...',
          bindField: 'description'
        }
      ],
      tabsConfig: [
        {id: 1, tabName: "未报送",value : "UN_ASSIGNED"},
        {id: 2, tabName: "已报送",value : "ASSIGNED"},
        {id: 3, tabName: "已确认",value : "CONFIRMED"},
        {id: 4, tabName: "退回中",value : "RETURNING"},
        {id: 5, tabName: "冻结",value : "FREEZE"},
        {id: 6, tabName: "全部",value : ""}
      ]
    }
  },
  methods:{
    doQuery(filters) {
      //获取分页信息
      const _currentIndex = this.$data.record.currentIndex
      const _pageSize = this.$data.record.pageSize
      this.$data.loading = true

      filters["orderStatus"] = "PAY"

      //获取页大小和页数
      this.apiListBaseOrder(filters, _pageSize, _currentIndex).then((response) => {
        this.$data.loading = false
        this.$data.record.total = response.data.total
        this.$data.record.list = response.data.data ? response.data.data : []
      })
    },
    search() {
      const filters = this.conditionFilter.getFilters()
      const tabIndex = this.tabFilter.getFilters()
      let tabFilterValue
      this.$data.tabsConfig.forEach(x=>{
        if(x.id === tabIndex){
          tabFilterValue = x.value
        }
      })
      this.$data.currentTab = tabFilterValue
      if(tabFilterValue !== ""){
        filters["assignStatus"] = tabFilterValue
      }

      this.doQuery(filters)
    },
    handleSizeChange(pageSize) {
      this.record.pageSize = pageSize
      this.search()
    },
    handleCurrentChange(pageIndex) {
      this.record.currentIndex = pageIndex
      this.search()
    },
    handleSelectionChange(changed) {
      this.$data.record.selected = changed.map(x => x.orderNo)
    },
    edit(orderNo){
      this.signMngDetailRef.openDetail(orderNo)
    },
    _downloadSignContent(orderNos){
      this.apiDownloadOrderContent(orderNos).then(response => {
        const contentDisposition = response.headers["content-disposition"]
        let fileName = "文件.zip";
        if(contentDisposition){
          const index = contentDisposition.indexOf("filename=");
          fileName = contentDisposition.substring(index+9)
        }
        const res = response.data

        const link = document.createElement('a');  // 创建元素
        let blob = new Blob([res], { type: 'application/x-download' });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);   // 创建下载的链接
        link.setAttribute('download', fileName);  // 给下载后的文件命名
        document.body.appendChild(link);
        link.click();  // 点击下载
        document.body.removeChild(link);  //  下载完成移除元素
        window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
      }).catch(err => {
        console.log(err)
        Message.error(err.message)
      })
    },
    doDownloadBatch(){
      if(this.$data.record.selected.length === 0){
        Message.warning("请选择要下载的订单")
        return
      }
      this._downloadSignContent(this.$data.record.selected)
    },
    doDownload(orderNo){
      this._downloadSignContent([orderNo])
    }
  },
  computed:{
    listData: function () {
      return this.$data.record.list.map((row) => {
        //处理备注
        if(row.description && row.description.length>70){
          row.__description = row.description.substring(0,70)+"..."
        }else {
          row.__description = row.description
        }

        //处理时间显示
        row.__dealTime = this.dateFormat(row.dealTime)
        return row
      })
    }
  }
}
</script>

<style scoped>

</style>