import request from "@/components/util/http";


export default {
    methods:{
        apiListBaseOrder(filters,pageSize,pageIndex){
            return request({
                url : '/api/shopping/oss/order/list/' + pageSize + '/' + pageIndex,
                method: 'get',
                params: filters
            })
        },
        apiGetOrderDetail(orderNo){
            return request({
                url: '/api/shopping/oss/order/'+orderNo,
                method: 'get'
            })
        },
        apiAssignSupplier(orderNo,supplierId){
            return request({
                url: '/api/shopping/oss/order/assignment/'+supplierId+"/"+orderNo,
                method: 'post'
            })
        },
        apiUpdateComment(orderNo,comment){
            return request({
                url: '/api/shopping/oss/order/'+orderNo+"/comment",
                method: 'put',
                data:{
                    comment: comment
                }
            })
        },
        apiGetOrderDetailSimple(orderNo){
            return request({
                url: '/api/shopping/oss/order/order-simple/'+orderNo,
                method: 'get'
            })
        },
        apiFreezeOrder(orderNo){
            return request({
                url: '/api/shopping/oss/order/'+orderNo+"/freeze",
                method: 'post'
            })
        },
        apiUnfreezeOrder(orderNo){
            return request({
                url: '/api/shopping/oss/order/'+orderNo+"/unfreeze",
                method: 'post'
            })
        }
    }
}