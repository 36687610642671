import request from "@/components/util/http";
import Storage from "@/components/util/Storage";

export default {
    mixins:[ Storage ],
    methods:{
        apiOrderLog(orderNo){
            return request({
                url : '/api/shopping/oss/order-log/'+orderNo,
                method: 'get',
            })
        }
    }
}