<template>
  <el-form :inline="true" :model="queryConditions" class="akz-input">
    <el-form-item v-for="filterInput in filterInputsMain" :label="filterInput.title" :key="filterInput.bindField">
      <el-input v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'input'"
                @keyup.enter="doQuery" @blur="doQuery" style="width: 250px"
                :placeholder="filterInput.placeHolder" prefix-icon="Search">
      </el-input>
      <!--选择输入框-->
      <el-select v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'select'" filterable="true"
                 style="width: 250px"
                 :placeholder="filterInput.placeHolder" @change="doQuery">
        <el-option v-for="option in filterInput.options" :label="option.label" :value="option.value"
                   :key="option.value"/>
      </el-select>
      <!--选择输入框(分组)-->
      <el-select v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'select-group'" filterable="true"
                 style="width: 250px"
                 :placeholder="filterInput.placeHolder" @change="doQuery">
        <el-option-group
            v-for="option in filterInput.options" :key="option.value" :label="option.label">
          <el-option v-for="_option in option.subs" :label="_option.label" :value="_option.value"
                     :key="_option.value"/>
        </el-option-group>
      </el-select>
      <!--日期时间选择器-->
      <el-date-picker v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'datetime'" @change="doQuery"
                      value-format="x" style="width: 250px" type="datetime" :placeholder="filterInput.placeHolder"/>
    </el-form-item>
    <el-form-item>
      <el-button v-if="filterConfig.length > 2" link @click="moreCondition" class="akz-link">更多筛选</el-button>
      <el-button link @click="refreshList" class="akz-link">刷新</el-button>
    </el-form-item>
  </el-form>
  <div class="condition-filters" v-if="filters && filters.length > 0">
    <div class="condition-filter-title">
      <el-icon>
        <Filter/>
      </el-icon>
      当前筛选：
    </div>
    <div class="condition-filter" v-for="condition in filters" :key="condition.field">
      {{ condition.showName }} : <span class="condition-value">{{ condition.filterValue }}</span>
      <el-icon class="condition-filter-close" @click="clearConditions(condition.field)">
        <Close/>
      </el-icon>
    </div>
    <div class="condition-clear">
      <el-button link style="color: #00CA7A;font-size: var(--el-font-size-extra-small)" @click="clearConditions()">
        全部清除
      </el-button>
    </div>
  </div>
  <div class="condition-date-ranges" v-if="showDateRanger">
      <el-date-picker
        v-model="dateRange"
        type="datetimerange"
        @change="doQuery"
        range-separator="到"
        start-placeholder="开始时间"
        end-placeholder="结束时间" />
    <div class="condition-date-item" @click="setDateRange('today')">近1日</div>
    <div class="condition-date-item condition-date-item-right" @click="setDateRange('thisWeek')">近1周</div>
  </div>
  <div>
    <el-drawer v-model="showModal" :show-close="true" custom-class="condition-more-pan">
      <template #header="{titleId, titleClass }">
        <h4 :id="titleId" :class="titleClass" style="color: #000;font-weight: 650;font-size: 14px;">
          <Filter width="20px" height="20px"/>
          <span style="margin-left: 8px;">筛选条件</span></h4>
      </template>
      <div class="condition-more-filters">
        <div v-for="filterInput in filterInputs" class="condition-more-filter" :key="filterInput.bindField">
          <div class="condition-more-filter-title">{{ filterInput.title }}</div>
          <div class="condition-more-filter-input">
            <!--普通输入框-->
            <el-input v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'input'"
                      :placeholder="filterInput.placeHolder" @keydown.enter="doQuery"></el-input>
            <!--选择输入框-->
            <el-select v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'select'" filterable="true"
                       style="width:100%"
                       :placeholder="filterInput.placeHolder">
            <el-option v-for="option in filterInput.options" :label="option.label" :value="option.value"
                         :key="option.value"/>
            </el-select>
            <!--选择输入框(分组)-->
            <el-select v-model="queryConditions[filterInput.bindField].value" v-if="filterInput.type === 'select-group'" filterable="true"
                       style="width:100%"
                       :placeholder="filterInput.placeHolder">
              <el-option-group
                  v-for="option in filterInput.options" :key="option.value" :label="option.label">
                  <el-option v-for="_option in option.subs" :label="_option.label" :value="_option.value"
                             :key="_option.value"/>
              </el-option-group>
            </el-select>
            <!--日期时间选择器-->
            <el-date-picker v-model="queryConditions[filterInput.bindField].value" clearable=false
                            v-if="filterInput.type === 'datetime'" value-format="x" style="width:100%" type="datetime"
                            :placeholder="filterInput.placeHolder"/>
          </div>
        </div>
      </div>
      <el-row :gutter="1" class="condition-more-footer">
        <el-col :span="12" style="position: relative">
          <div class="none-select condition-more-footer-btn condition-more-footer-btn-query" @click="doQuery">查询</div>
          <div class="none-select condition-more-footer-splitter"></div>
        </el-col>
        <el-col :span="12">
          <div class="condition-more-footer-btn condition-more-footer-btn-clear" @click="clearConditions(null)">清除筛选
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>

import DateFormat from "@/components/util/DateFormat";
import {reactive} from "vue";

export default {
  name: "ConditionFilter",
  props: {
    //是否打开时显示更多模态窗口
    showDefault: {
      type: Boolean,
      default: false
    },
    //筛选条件配置数据
    filterConfig: Array,
    //是否点击查询按钮后关闭模态窗口
    closeAfterQuery: {
      type: Boolean,
      default: false
    },
    //是否显示时间范围筛选框
    showDateRanger:{
      type: Boolean,
      default: false
    },
    //
    dateRangeDefault:{
      type: String,
      default : ''
    },
    //开始时间字段
    startDateFieldName:{
      type: String,
      default : "startTime"
    },
    //结束时间字段
    endDateFieldName: {
      type: String,
      default : "endTime"
    },
    doQueryWhenStart:{
      type: Boolean,
      default: false
    }
  },
  emits: {
    "do-query": null
  },
  mixins:[DateFormat],
  data() {
    return {
      showModal: false,
      //筛选条件绑定model
      queryConditions: {},
      //筛选器输入框配置
      filterInputs: [],
      //筛选器输入框配置(主页面)
      filterInputsMain: [],
      //生效的查询条件
      filters: [],
      //时间范围筛选
      dateRange:[]
    }
  },
  methods: {
    doQuery() {
      //触发query事件
      this.$emit("do-query", this.getFilters())
      //关闭模态
      if (this.$props.closeAfterQuery === true) {
        this.$data.showModal = false
      }
    },
    getFilters(){
      //构建筛选条件数据&构建筛选项
      let filterRule = []
      this.$data.filters = []
      for (let _fieldName in this.$data.queryConditions) {
        let condition = this.$data.queryConditions[_fieldName]
        let filterComponent = this.getFilterInputComponent(_fieldName)
        filterRule.push({
          fieldName: _fieldName,
          value: this.transFieldValueWithType(filterComponent.type, condition.value)
        })
        //处理筛选tag
        if (typeof condition.value === "undefined" || condition.value === null || condition.value === "") {
          continue
        }
        this.$data.filters.push({
          field: _fieldName,
          showName: filterComponent.title,
          filterValue: this.transFieldValueWithTypeInFilterShow(filterComponent.type, condition.value, filterComponent)
        })
      }
      if(this.$props.showDateRanger === true){
        if(this.$data.dateRange){
          if(!(this.$data.dateRange[0] === "" || this.$data.dateRange[1] === "")){
            filterRule.push({
              fieldName : this.$props.startDateFieldName,
              value : this.dateFormat(this.$data.dateRange[0])
            })
            filterRule.push({
              fieldName : this.$props.endDateFieldName,
              value : this.dateFormat(this.$data.dateRange[1])
            })
          }
        }
      }
      //准备requestParam
      const requestParam = {}
      for (const {fieldName,value} of filterRule) {
        if(fieldName.indexOf("_")!==0 && value !== ""){
          requestParam[fieldName] =value;
        }
      }
      return requestParam
    },
    moreCondition() {
      this.$data.showModal = true
    },
    clearConditions(fieldName) {
      if (typeof fieldName !== "undefined" && fieldName != null) {
        this.$data.queryConditions[fieldName].value = ""
        this.$data.filters = this.$data.filters.filter((data) => data.field !== fieldName)
      } else {
        for (let ele in this.$data.queryConditions) {
          this.$data.queryConditions[ele].value = ""
        }
        this.$data.filters = []
      }
      this.doQuery()
    },
    getFilterInputComponent(fieldName) {
      for (let input of this.$data.filterInputs) {
        if (input.bindField === fieldName) {
          return input
        }
      }
    },
    transFieldValueWithType(type, value) {
      switch (type) {
        case "input":
        case "select":
        case "datetime":
        case "select-group":
          return value;
      }
    },
    transFieldValueWithTypeInFilterShow(type, value, componentInput) {
      switch (type) {
        case "input":
          return this.subString(value, 5);
        case "select":
        case "select-group":
          return this.subString(this.getShowNameOfSelectOption(componentInput.options, value), 5);
        case "datetime":
          return this.formatTimestamp(value);
      }
    },
    getShowNameOfSelectOption(options, value) {
      if (typeof options != "undefined" && options != null) {
        for (const option of options) {
          if(option.subs){
            for(const sub of option.subs){
              if (sub.value === value) {
                return sub.label
              }
            }
          }
          if (option.value === value) {
            return option.label
          }
        }
      }
      return value;
    },
    subString(value, length) {
      let valueStr = new String(value);
      if (valueStr.length > (length+2)) {
        return valueStr.substring(0, length)+"..."
      } else {
        return value
      }
    },
    formatTimestamp(timestamp) {
      let date = new Date(timestamp)
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = date.getDate() + ' ';
      let h = date.getHours() + ':';
      if (h.length === 2) {
        h = "0" + h
      }
      let m = date.getMinutes() + ':';
      if (m.length === 2) {
        m = "0" + m
      }
      let s = date.getSeconds() + "";
      if (s.length === 1) {
        s = "0" + s
      }
      return Y + M + D + h + m + s;
    },
    setDateRange(type){
      this._setDateRange(type)
      this.doQuery()
    },
    _setDateRange(type){
      let now = new Date()
      let start
      if(type === "today"){
        start = new Date(now.getTime()-24*60*60*1000);
      }else if(type === "thisWeek" ){
        start = new Date(now.getTime()-24*60*60*1000*7);
      }else{
        start = ''
      }
      this.$data.dateRange = [start,now]
    },
    refreshList(){
      this.doQuery()
    }
  },
  mounted() {
    const mainFilters = []
    this.$data.filterInputs = this.$props.filterConfig.map((x) => {
      //初始化筛选控件绑定字段
      this.$data.queryConditions[x.bindField] = {
        filed: x.bindField,
        value: ""
      }
      let _type;
      if(x.type === "select-remote"){
        _type = "select"
      }else if(x.type === "select-group-remote"){
        _type = "select-group"
      }else{
        _type = x.type
      }

      //显示在主页面
      if (x.showInMain === true) {
        mainFilters.push({
          type: _type,
          title: x.title,
          placeHolder: x.placeHolder ? x.placeHolder : "请输入",
          bindField: x.bindField,
          options: x.options
        })
      }
      this.$data.filterInputsMain = mainFilters;

      return {
        type: _type,
        title: x.title,
        placeHolder: x.placeHolder ? x.placeHolder : "请输入",
        bindField: x.bindField,
        options: x.options
      }
    })

    this.$data.showModal = this.$props.showDefault
    //初始化dateRange的值
    if(this.$props.showDateRanger === true){
      this._setDateRange(this.$props.dateRangeDefault)
    }
    //开始时触发搜索
    if(this.$props.doQueryWhenStart === true){
      this.doQuery()
    }
    //处理远端调用获取数据的select
    this.$props.filterConfig.forEach(x=>{
      if(x.type === "select-remote" || x.type === "select-group-remote"){
        if(x.source){
          x.source(x.sourceParams).then(response => {
            const _fieldRemoteData = []
            if(!response.data){
              response.data = []
            }
            response.data.forEach(y => {
              if(x.type === "select-group-remote"){
                let subs = y[x.subsField]
                const subArray = []
                if(subs){
                  subs.forEach(s => {
                    subArray.push({
                      label: s[x.textField],
                      value: s[x.valueField]
                    })
                  })
                }
                _fieldRemoteData.push({
                  label: y[x.textField],
                  value: y[x.valueField],
                  subs: subArray
                })
              }else {
                _fieldRemoteData.push({
                  label: y[x.textField],
                  value: y[x.valueField]
                })
              }

            })
            this.$data.filterInputs.forEach(_input =>{
              if(_input.bindField === x.bindField){
                _input.options = reactive(_fieldRemoteData)
              }
            })
            this.$data.filterInputsMain.forEach(_input => {
              if(_input.bindField === x.bindField){
                _input.options = reactive(_fieldRemoteData)
              }
            })
          })
        }
      }
    })
  }
}
</script>

<style>
.condition-filters {
  margin-bottom: 10px;
  font-size: var(--el-font-size-extra-small);
}

.condition-filter-title, .condition-filter {
  color: #A4A4A4;
  display: inline-block;
  padding: 4px 8px;
  line-height: 18px;
}
.condition-filter-title{
  padding-left: 0;
}

.condition-filter {
  background-color: white;
  margin-right: 5px;
}

.condition-filters .el-icon {
  vertical-align: middle !important;
}

.condition-filter-close {
  cursor: pointer;
}

.condition-value {
  color: #00CA7A;
  margin: 0 3px;
  display: inline-block;
  line-height: 18px;
}

.condition-clear, .condition-clear button {
  display: inline-block;
  font-weight: 400;
  padding-left: 6px;
}

.condition-more-pan .el-drawer__header {
  background: #fff;
  padding: 18px;
  margin-bottom: 0 !important;
}

.condition-more-pan .el-drawer__body {
  background-color: #f2f2f2;
  padding: 0 0 47px 0 !important;
}

.condition-more-pan .condition-more-filters {
  padding: 0 20px;
}

.condition-more-pan .condition-more-filters .condition-more-filter-title {
  margin-top: 10px;
  padding-bottom: 5px;
}

.condition-more-pan .condition-more-filters .condition-more-filter {
  font-size: var(--el-font-size-base);
}

.condition-more-pan header .el-drawer__title svg {
  vertical-align: sub;
  color: gray;
}

.condition-more-footer {
  background-color: #ffffff;
  position: absolute !important;
  bottom: 0;
  width: 100%;
}

.condition-more-footer-splitter {
  height: 30px;
  width: 1px;
  background: #eee;
  position: absolute;
  right: 0;
  top: 5px;
  display: inline-block;
}

.condition-more-footer-btn {
  text-align: center;
  line-height: 47px;
  position: relative;
  font-size: var(--el-font-size-medium);
  cursor: pointer;
}

.condition-more-footer-btn.condition-more-footer-btn-query {
  color: #42b983;
}

.condition-more-footer-btn.condition-more-footer-btn-clear {
  color: #303133;
}

.none-select {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

.akz-link {
  color: #00CA7A !important;
  font-weight: 400 !important;
}

.akz-input label, .akz-input input {

}
.akz-input .el-icon{
  font-size: var(--el-font-size-medium);
}
.akz-input .el-form-item,.condition-date-ranges{
  margin-bottom: 10px;
}
.condition-date-ranges{
  font-size: var(--el-font-size-base);

}
.condition-date-ranges .condition-date-item{
  display: inline-block;
  padding:7px 0px 6px 33px;
  vertical-align: top;
  cursor: pointer;
  color: #00CA7A;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.condition-date-ranges .condition-date-item:hover{
  color: #00CA7A;
}
.condition-date-ranges .condition-date-item.condition-date-item-right,.condition-date-ranges .el-date-editor{
  margin-left: 0px;
}
.el-range-separator{
  font-size: var(--el-font-size-base) !important;
}

</style>